import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from '../App';
import { homeRoutes } from './routes/homeRoutes';
import { errorRoutes } from './routes/errorRoutes';

const router = createBrowserRouter([
	{
		path: '/',
		element: <App />,
		children: [...homeRoutes, ...errorRoutes],
	},
]);

const Router = () => {
	return <RouterProvider router={router} />;
};

export default Router;

/* eslint-disable no-mixed-spaces-and-tabs */
import {
	Group,
	Header,
	HeaderProps,
	Image,
	UnstyledButton,
	Flex,
	Menu,
	Box,
	Burger,
	Collapse,
	Drawer,
	Stack,
	Space,
	Text,
} from '@mantine/core';
import React, { useEffect } from 'react';
import { useNavigate } from '../lib/navigate';
import tcguniteLogo from '../views/assets/logo.png';
import NavLink from './NavLink';
import { HomeRoutes } from 'router/constants/Home.routes';
import { ResponsiveContainer } from 'components';
import { useDisclosure } from '@mantine/hooks';
import { useLocation } from 'react-router-dom';
import Icon from 'assets/icons';
import { useTranslation } from 'react-i18next';

export type TopbarProps = Omit<HeaderProps, 'children' | 'height'>;

const Topbar = React.forwardRef<HTMLElement, TopbarProps>(
	({ ...rest }, ref) => {
		const navigate = useNavigate();
		const location = useLocation();
		const { t, i18n } = useTranslation();

		const [opened, { toggle }] = useDisclosure(false);
		useEffect(() => {
			if (opened) toggle();
		}, [location]);

		const onChangeLanguage = () => {
			const nextLanguage = i18n.language === 'en' ? 'ja' : 'en';
			i18n.changeLanguage(nextLanguage);
		};

		return (
			<Header
				{...rest}
				height="80px"
				ref={ref}
				className={
					(location.pathname === HomeRoutes.Home ||
					location.pathname === HomeRoutes.AboutUs
						? opened
							? 'md:bg-secondary-black'
							: 'bg-transparent absolute'
						: 'bg-secondary-white') +
					' border-none ease-linear duration-100 transition-colors'
				}
			>
				<ResponsiveContainer className="h-full">
					<Flex justify="space-between" align="center" className="h-full">
						<Group className="gap-4">
							<UnstyledButton onClick={() => navigate('/')}>
								<Image width={60} fit="contain" src={tcguniteLogo}></Image>
							</UnstyledButton>
							<UnstyledButton onClick={onChangeLanguage}>
								<Group className="gap-2">
									<Icon
										name="translate"
										className={
											(location.pathname === HomeRoutes.Home ||
											location.pathname === HomeRoutes.AboutUs
												? 'text-secondary-white'
												: 'text-secondary-dark-blue') + ' text-xl'
										}
									/>
									<Text
										className={
											(location.pathname === HomeRoutes.Home ||
											location.pathname === HomeRoutes.AboutUs
												? 'text-secondary-white'
												: 'text-secondary-dark-blue') +
											' text-md font-bold uppercase'
										}
									>
										{i18n.language}
									</Text>
								</Group>
							</UnstyledButton>
						</Group>
						<Group spacing="xl" className="xl:flex hidden">
							<NavLink label="Home" to={HomeRoutes.Home} />
						</Group>
						<Box className="xl:hidden block">
							<Menu shadow="md" width={200}>
								<Menu.Target>
									<Burger
										opened={opened}
										onClick={toggle}
										color={
											location.pathname === HomeRoutes.Home ||
											location.pathname === HomeRoutes.AboutUs
												? 'white'
												: ''
										}
									/>
								</Menu.Target>
							</Menu>
						</Box>
					</Flex>
				</ResponsiveContainer>
				<Collapse
					in={opened}
					transitionDuration={100}
					transitionTimingFunction="linear"
					className={
						(location.pathname === HomeRoutes.Home ||
						location.pathname === HomeRoutes.AboutUs
							? opened
								? 'bg-secondary-black'
								: ''
							: 'bg-secondary-white') + ' xl:hidden md:block hidden pb-2'
					}
				>
					<ResponsiveContainer>
						<Group spacing="xl" className="justify-end">
							<NavLink label={t('home')} to={HomeRoutes.Home} />
						</Group>
					</ResponsiveContainer>
				</Collapse>
				<Drawer
					position="right"
					size="100%"
					opened={opened}
					onClose={toggle}
					withCloseButton={false}
					className="md:hidden block top_bar__drawer"
				>
					<ResponsiveContainer>
						<Group className="justify-between">
							<Image width={60} fit="contain" src={tcguniteLogo}></Image>
							<UnstyledButton onClick={toggle}>
								<Icon name="doublearrowup" className="text-secondary-white" />
							</UnstyledButton>
						</Group>
						<Space className="h-12"></Space>
						<Stack className="items-center">
							<UnstyledButton
								onClick={() => navigate('/')}
								className="font-semibold text-secondary-white text-xl"
							>
								{t('home')}
							</UnstyledButton>
						</Stack>
					</ResponsiveContainer>
				</Drawer>
			</Header>
		);
	}
);

Topbar.displayName = 'Topbar';

export default Topbar;

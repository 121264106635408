import React from 'react';
import {
	Flex,
	ResponsiveContainer,
	Stack,
	Text,
	ViewContainer,
	Image,
	List,
	UnstyledButton,
	Space,
} from '../../components';
import { useNavigate } from '../../lib/navigate';
import mascotLargeImage from '../assets/error/mascot_large.png';
import mascotSmallImage from '../assets/error/mascot_small.png';
import { HomeRoutes } from 'router/constants/Home.routes';

const Error404 = () => {
	const navigate = useNavigate();

	return (
		<ViewContainer title="404">
			<ResponsiveContainer>
				<Flex className="md:gap-8  gap-2 justify-between xl:items-center items-start md:flex-row flex-col-reverse">
					<Stack className="gap-8">
						<Stack>
							<Text className="font-['Bungee'] text-primary-brilliant-blue text-8xl md:text-left text-center">
								OOPS!
							</Text>
							<Text className="text-primary-brilliant-blue text-4xl md:text-left text-center font-semibold">
								{"We can't find that page!"}
							</Text>
						</Stack>
						<Text className="text-secondary-black text-left">
							Looks like the link you clicked has gone off on its own adventure.
						</Text>
						<Stack className="gap-0">
							<Text className="text-secondary-black text-left">
								{"Don't worry! Here's what you can do:"}
							</Text>
							<List listStyleType="disc">
								<List.Item>
									<UnstyledButton
										className="font-medium underline"
										onClick={() => navigate(HomeRoutes.Home)}
									>
										Home
									</UnstyledButton>
									<span>: Head back to our main page!</span>
								</List.Item>
							</List>
						</Stack>
						<Text className="text-secondary-black text-left">
							{
								"Thanks for being part of our community! Let's get you back to the fun!"
							}
						</Text>
					</Stack>
					<Image
						src={mascotLargeImage}
						alt="Mascot"
						fit="contain"
						className="w-96 xl:block hidden"
					/>
					<Image
						src={mascotSmallImage}
						alt="Mascot"
						fit="contain"
						className="w-44 xl:hidden block md:mx-0 mx-auto"
					/>
				</Flex>
			</ResponsiveContainer>
			<Space className="h-28" />
		</ViewContainer>
	);
};

export default Error404;

import React from 'react';
import { Container, Stack, Text, Image, Space, Box, Flex } from '@mantine/core';
import { ResponsiveContainer, ViewContainer } from 'components';
import giftawaysImage from '../views/assets/home/giftaways.png';
import communityBuildingImage from '../views/assets/home/community_building.png';
import easeOfUseImage from '../views/assets/home/ease_of_use.png';
import largeCollectImage from '../views/assets/home/large_collect.png';
import largePlayImage from '../views/assets/home/large_play.png';
import largeUniteImage from '../views/assets/home/large_unite.png';
import collectImage from '../views/assets/home/collect.png';
import playImage from '../views/assets/home/play.png';
import uniteImage from '../views/assets/home/unite.png';
import backdropDesktopImage from '../views/assets/home/backdrop_desktop.png';
import backdropTabletImage from '../views/assets/home/backdrop_tablet.png';
import backdropMobileImage from '../views/assets/home/backdrop_mobile.png';
import mascotImage from '../views/assets/home/mascot.png';
import Topbar from 'layouts/Topbar';
import { Trans, useTranslation } from 'react-i18next';

const Home = () => {
	const { t } = useTranslation();

	const featuresArray = [
		{
			title: t('giveaways'),
			description: t('we_believe_in_rewarding_our_community'),
			image: giftawaysImage,
		},
		{
			title: t('community_building'),
			description: t('connect_your_favorites_stores_and_users'),
			image: communityBuildingImage,
		},
		{
			title: t('ease_of_user'),
			description: t('designed_to_be_user_friendly_and_efficient'),
			image: easeOfUseImage,
		},
	];

	const aimsArray = [
		{
			title: t('collect'),
			description: t('unbox_the_excitement_discover_hidden_treasures'),
			largeImage: largeCollectImage,
			image: collectImage,
		},
		{
			title: t('play'),
			description: t('immerse_yourself_in_the_thrilling_world_of_tcgs'),
			largeImage: largePlayImage,
			image: playImage,
		},
		{
			title: t('unite'),
			description: t('join_a_global_community_of_passionate_tcg_enthusiasts'),
			largeImage: largeUniteImage,
			image: uniteImage,
		},
	];

	return (
		<ViewContainer title="Home">
			<Topbar></Topbar>
			<img
				src={backdropDesktopImage}
				alt="Backdrop"
				className="absolute top-0 bottom-0 h-full w-full z-1 object-cover object-top xl:block hidden"
			/>
			<img
				src={backdropTabletImage}
				alt="Backdrop"
				className="absolute top-0 bottom-0 h-full w-full z-1 object-cover object-top xl:hidden md:block hidden"
			/>
			<img
				src={backdropMobileImage}
				alt="Backdrop"
				className="absolute top-0 bottom-0 h-full w-full z-1 object-cover object-top md:hidden block"
			/>
			<Space className="md:h-16 h-6" />
			<ResponsiveContainer className="bg-secondary-dark-blue z-10 relative"></ResponsiveContainer>
			<ResponsiveContainer className="z-10 relative">
				<Text className="text-secondary-white md:text-7xl text-6xl font-['Bungee'] md:text-center text-left">
					<span className="break-keep">{t('collect')},</span>{' '}
					<span className="break-keep">{t('play')},</span>{' '}
					<span className="break-keep">{t('unite')}</span>
				</Text>
			</ResponsiveContainer>
			<Container className="h-12" />
			<ResponsiveContainer className="z-10 relative">
				<Flex className="gap-4 xl:flex-row flex-col-reverse xl:justify-center md:items-center items-start">
					{/* TODO: Redirect to early access page */}
					<Box className="bg-primary-old-gold rounded-3xl px-8 py-2.5 drop-shadow-lg md:w-52 w-full">
						<Text className="font-semibold text-secondary-black text-md text-center">
							{t('get_early_access')}
						</Text>
					</Box>
					<Text className="text-secondary-white text-xs font-semibold text-left md:w-60 w-full">
						{t('better_place_for_better_community')}
					</Text>
				</Flex>
			</ResponsiveContainer>
			<Space className="h-20" />

			<ResponsiveContainer className="z-10 relative">
				<Box className="md:h-60 h-28" />
				<Image
					src={mascotImage}
					alt="Mascot"
					fit="contain"
					className="md:w-80 w-44 absolute z-20 md:inset-x-0 md:mx-auto top-[-3rem]"
				/>
				<Flex className="md:gap-3 gap-6 justify-center flex-nowrap md:flex-row flex-col">
					{featuresArray.map(({ title, description, image }) => (
						<Box
							key={title}
							className="basis-full relative shadow-inner rounded-xl shadow-primary-white"
						>
							<Stack className="gap-0">
								<Box className="bg-primary-white-500 rounded-t-xl h-48 w-full md:block hidden"></Box>
								<Box className="bg-primary-white-300 md:rounded-b-xl md:rounded-none rounded-xl md:h-48 h-44 w-full px-4">
									<Stack className="justify-center h-full">
										<Text className="text-secondary-white text-lg font-semibold text-left md:pr-0 pr-20">
											{title}
										</Text>
										<Text className="text-secondary-white text-xs text-left">
											{description}
										</Text>
									</Stack>
								</Box>
								<Image
									src={image}
									alt="Giveaways"
									fit="contain"
									className="md:w-36 w-20 absolute md:top-14 top-[-12px] md:inset-x-0 md:mx-auto md:right-0 right-4"
								/>
							</Stack>
						</Box>
					))}
				</Flex>
			</ResponsiveContainer>
			<Space className="h-28" />
			<div className="marquee font-['Bungee'] text-5xl text-secondary-white z-10 relative max-w-[2500px] mx-auto">
				<span>
					{t('collect')} &nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp; {t('play')}
					&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp; {t('unite')}
					&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;
				</span>
				<span>
					{t('collect')} &nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp; {t('play')}
					&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp; {t('unite')}
					&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;
				</span>
				<span>
					{t('collect')} &nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp; {t('play')}
					&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp; {t('unite')}
					&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;
				</span>
				<span>
					{t('collect')} &nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp; {t('play')}
					&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp; {t('unite')}
					&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;
				</span>
			</div>
			<Space className="h-28" />
			<ResponsiveContainer className="z-10 relative">
				<Stack className="gap-8">
					{aimsArray.map(({ title, description, largeImage, image }) => (
						<Box
							key={title}
							className="bg-primary-white-200 p-8 max-w-none shadow-inner rounded-xl shadow-primary-white"
						>
							<Flex className="flex-nowrap gap-8 xl:flex-row flex-col items-center">
								<Stack className="gap-1 justify-center">
									<Text className="font-['Bungee'] text-secondary-white text-5xl md:text-left text-center">
										{title}
									</Text>
									<Space h="md" />
									<Text className="text-secondary-white md:text-lg text-xs text-left">
										{description}
									</Text>
								</Stack>
								<Image
									src={largeImage}
									alt={title}
									fit="contain"
									className="xl:w-[40rem] xl:block hidden"
								/>
								<Image
									src={image}
									alt={title}
									fit="contain"
									className="xl:hidden"
								/>
							</Flex>
						</Box>
					))}
				</Stack>
			</ResponsiveContainer>
			<Space className="h-28" />
			<ResponsiveContainer className="z-10 relative">
				<Box className="bg-primary-white w-full rounded-[2rem] md:px-14 px-4 md:py-24 py-12">
					<Text className="font-['Bungee'] md:text-7xl text-5xl md:text-center text-left break-words signup-hero text-primary-old-gold">
						<Trans i18nKey="sign_up_early_for_amazing_free_gifts" />
					</Text>
					<Space h="xl" />
					<Text className="text-md font-semibold md:text-center text-left">
						{t('dont_wait_join_the_fun_and_snag_your_gifts_today')}
					</Text>
					<Space h="xl" />
					<Flex className="justify-center">
						{/* TODO: Redirect to register page */}
						<Box className="bg-primary-old-gold rounded-3xl px-8 py-2.5 drop-shadow-lg">
							<Text className="font-semibold text-secondary-black text-md text-center">
								{t('pre_register_now')}!
							</Text>
						</Box>
					</Flex>
				</Box>
			</ResponsiveContainer>
			<Space className="h-28" />
		</ViewContainer>
	);
};

export default Home;

import { Container, ContainerProps } from '@mantine/core';
import React from 'react';
export type ResponsiveContainerProps = React.PropsWithChildren<ContainerProps>;

export const ResponsiveContainer = React.forwardRef<
	HTMLDivElement,
	ResponsiveContainerProps
>(({ children, className, ...rest }, ref) => {
	return (
		<Container
			className={`xl:max-w-[1280px] md:max-w-[768px] max-w-[320px] md:px-8 px-2  ${
				className ?? ''
			}`}
			{...rest}
			ref={ref}
		>
			{children}
		</Container>
	);
});

ResponsiveContainer.displayName = 'ResponsiveContainer';

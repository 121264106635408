import React from 'react';
import {
	NavLinkProps as MantineNavLinkProps,
	Text,
	UnstyledButton,
	Stack,
	Container,
} from '@mantine/core';
import { useNavigate } from '../lib/navigate';
import { HomeRoutes } from 'router/constants/Home.routes';
import { useLocation } from 'react-router-dom';

export type NavLinkProps = {
	to?: string;
} & Omit<MantineNavLinkProps, 'icon'>;

const NavLink = React.forwardRef<HTMLButtonElement, NavLinkProps>(
	({ to, label }, ref) => {
		const navigate = useNavigate();
		const { pathname } = useLocation();

		const Component = () => (
			<Stack spacing="0">
				<Container></Container>
				<UnstyledButton
					className="font-semibold text-sm text-center py-1"
					onClick={() => navigate(to ?? HomeRoutes.Home)}
					ref={ref}
				>
					<Text
						weight="600"
						className={
							pathname === HomeRoutes.Home ||
							location.pathname === HomeRoutes.AboutUs
								? 'text-primary-white'
								: 'text-secondary-black'
						}
					>
						{label}
					</Text>
				</UnstyledButton>
			</Stack>
		);

		return <Component />;
	}
);

NavLink.displayName = 'NavLink';

export default NavLink;

/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';
import { StyledViewContainer } from './ViewContainer.styled';
import { Helmet } from 'react-helmet-async';

export type ViewContainerProps = React.PropsWithChildren<{
	title: string;
}>;

export const ViewContainer = React.forwardRef<
	HTMLDivElement,
	ViewContainerProps
>(({ children, title, ...rest }, ref) => {
	return (
		<StyledViewContainer {...rest} ref={ref}>
			<Helmet>
				<title>{title} | TCG Unite</title>
				<meta
					name="description"
					content="A better place for a better community. Your one stop for everything TCG."
				/>
				<meta
					property="og:image"
					key="og:image"
					content={`${process.env.BASE_URL}/images/opengraph_image.jpg`}
				/>
			</Helmet>
			{children}
		</StyledViewContainer>
	);
});

ViewContainer.displayName = 'ViewContainer';

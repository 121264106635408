import React from 'react';

import {
	Delete,
	FacebookOutlined,
	Instagram,
	YouTube,
	NorthEastRounded,
	KeyboardDoubleArrowUpRounded,
	Translate,
	X,
	SouthWestRounded,
} from '@mui/icons-material';
import { IconProps } from './index';
import { ReactComponent as TikTok } from '../../views/assets/social/tiktok.svg';

export const iconsMapper = {
	delete: (props: Omit<IconProps, 'name'>) => <Delete {...props} />,
	facebook: (props: Omit<IconProps, 'name'>) => <FacebookOutlined {...props} />,
	instagram: (props: Omit<IconProps, 'name'>) => <Instagram {...props} />,
	twitter: (props: Omit<IconProps, 'name'>) => <X {...props} />,
	youtube: (props: Omit<IconProps, 'name'>) => <YouTube {...props} />,
	tiktok: (props: Omit<IconProps, 'name'>) => <TikTok {...props} />,
	northeastarrow: (props: Omit<IconProps, 'name'>) => (
		<NorthEastRounded {...props} />
	),
	southwestarrow: (props: Omit<IconProps, 'name'>) => (
		<SouthWestRounded {...props} />
	),
	doublearrowup: (props: Omit<IconProps, 'name'>) => (
		<KeyboardDoubleArrowUpRounded {...props} />
	),
	translate: (props: Omit<IconProps, 'name'>) => <Translate {...props} />,
};

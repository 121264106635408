import React from 'react';
import { ResponsiveContainer, ViewContainer } from 'components';
import { t } from 'i18next';
import { Anchor, List, Space, Text, UnstyledButton } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { HomeRoutes } from 'router/constants/Home.routes';

const PrivacyPolicy = () => {
	const navigate = useNavigate();

	return (
		<ViewContainer title="Home">
			<ResponsiveContainer className="xl:max-w-[1000px] z-10 relative">
				<Text className="text-primary-brilliant-blue xl:text-7xl text-5xl font-['Bungee'] text-left">
					{t('privacy_policy')}
				</Text>
				<Space h="md" />
				<Text className="text-center text-sm">
					Effective: December 08, 2024
				</Text>
				<Text className="text-center text-sm">
					Last updated: December 08, 2024
				</Text>
				<Space h="md" />
				<Text>
					This Privacy Policy describes how TCGUnite (the &quot;Site&quot;,
					&quot;we&quot;, &quot;us&quot;, or &quot;our&quot;) collects, uses,
					and discloses your personal information when you visit, use our
					services, or make a purchase from tcgunite.co (the &quot;Site&quot;)
					or otherwise communicate with us (collectively, the
					&quot;Services&quot;). For purposes of this Privacy Policy,
					&quot;you&quot; and &quot;your&quot; means you as the user of the
					Services, whether you are a customer, website visitor, or another
					individual whose information we have collected pursuant to this
					Privacy Policy. Please read this Privacy Policy carefully. By using
					and accessing any of the Services, you agree to the collection, use,
					and disclosure of your information as described in this Privacy
					Policy. If you do not agree to this Privacy Policy, please do not use
					or access any of the Services.
				</Text>
				<Space h="sm" />
				<Text>
					We use Your Personal data to provide and improve the Service. By using
					the Service, You agree to the collection and use of information in
					accordance with this Privacy Policy.
				</Text>
				<Space h="md" />
				<Text className="text-xl font-bold">
					Interpretation and Definitions
				</Text>
				<Space h="xs" />
				<Text className="text-md font-semibold">Interpretation</Text>
				<Text>
					The words of which the initial letter is capitalized have meanings
					defined under the following conditions. The following definitions
					shall have the same meaning regardless of whether they appear in
					singular or in plural.
				</Text>
				<Space h="sm" />
				<Text className="text-md font-semibold">Definitions</Text>
				<Text>For the purposes of this Privacy Policy:</Text>
				<List icon={<Text>-</Text>}>
					<List.Item>
						<strong>Account</strong> means a unique account created for You to
						access our Service or parts of our Service.
					</List.Item>
					<List.Item>
						<strong>Affiliate</strong> means an entity that controls, is
						controlled by or is under common control with a party, where
						&quot;control&quot; means ownership of 50% or more of the shares,
						equity interest or other securities entitled to vote for election of
						directors or other managing authority.
					</List.Item>
					<List.Item>
						<strong>Company</strong> (referred to as either &quot;the
						Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in
						this Agreement) refers to TCGUnite, need to add - company address.
					</List.Item>
					<List.Item>
						<strong>Cookies</strong> are small files that are placed on Your
						computer, mobile device or any other device by a website, containing
						the details of Your browsing history on that website among its many
						uses.
					</List.Item>
					<List.Item>
						<strong>Country</strong> refers to: Singapore, United States of
						America & Japan
					</List.Item>
					<List.Item>
						<strong>Device</strong> means any device that can access the Service
						such as a computer, a cellphone or a digital tablet.
					</List.Item>
					<List.Item>
						<strong>Personal Data</strong> is any information that relates to an
						identified or identifiable individual.
					</List.Item>
					<List.Item>
						<strong>Service</strong> refers to the Website.
					</List.Item>
					<List.Item>
						<strong>Service Provider</strong> means any natural or legal person
						who processes the data on behalf of the Company. It refers to
						third-party companies or individuals employed by the Company to
						facilitate the Service, to provide the Service on behalf of the
						Company, to perform services related to the Service or to assist the
						Company in analyzing how the Service is used.
					</List.Item>
					<List.Item>
						<strong>Third-party Social Media Service</strong> refers to any
						website or any social network website through which a User can log
						in or create an account to use the Service.
					</List.Item>
					<List.Item>
						<strong>Usage Data</strong> refers to data collected automatically,
						either generated by the use of the Service or from the Service
						infrastructure itself (for example, the duration of a page visit).
					</List.Item>
					<List.Item>
						<strong>Website</strong> refers to tcgunite, accessible from{' '}
						<UnstyledButton onClick={() => navigate(HomeRoutes.Home)}>
							https://www.tcgunite.co/
						</UnstyledButton>
					</List.Item>
					<List.Item>
						<strong>You</strong> means the individual accessing or using the
						Service, or the company, or other legal entity on behalf of which
						such individual is accessing or using the Service, as applicable.
					</List.Item>
				</List>
				<Space h="md" />
				<Text className="text-xl font-bold">
					Collecting and Using Your Personal Data
				</Text>
				<Space h="xs" />
				<Text className="text-xl font-semibold">Types of Data Collected</Text>
				<Space className="h-1" />
				<Text className="text-md font-semibold">Personal Data</Text>
				<Text>
					While using Our Service, We may ask You to provide Us with certain
					personally identifiable information that can be used to contact or
					identify You. Personally identifiable information may include, but is
					not limited to:
				</Text>
				<List icon={<Text>-</Text>}>
					<List.Item>Email address</List.Item>
					<List.Item>First name and last name</List.Item>
					<List.Item>Phone number</List.Item>
					<List.Item>Address, State, Province, ZIP/Postal code, City</List.Item>
					<List.Item>Usage Data</List.Item>
				</List>
				<Space h="xs" />
				<Text className="text-md font-semibold">Usage Data</Text>
				<Text>
					Usage Data is collected automatically when using the Service.
				</Text>
				<Space h="sm" />
				<Text>
					{`Usage Data may include information such as Your Device's Internet
	Protocol address (e.g. IP address), browser type, browser version, the
	pages of our Service that You visit, the time and date of Your visit,
	the time spent on those pages, unique device identifiers and other
	diagnostic data.`}
				</Text>
				<Space h="sm" />
				<Text>
					When You access the Service by or through a mobile device, We may
					collect certain information automatically, including, but not limited
					to, the type of mobile device You use, Your mobile device unique ID,
					the IP address of Your mobile device, Your mobile operating system,
					the type of mobile Internet browser You use, unique device identifiers
					and other diagnostic data.
				</Text>
				<Space h="sm" />
				<Text>
					We may also collect information that Your browser sends whenever You
					visit our Service or when You access the Service by or through a
					mobile device.
				</Text>
				<Space h="md" />
				<Text className="text-md font-semibold">
					Information from Third-Party Social Media Services
				</Text>
				<Text>
					The Company allows You to create an account and log in to use the
					Service through the following Third-party Social Media Services:
				</Text>
				<List icon={<Text>-</Text>}>
					<List.Item>Google</List.Item>
					<List.Item>Facebook</List.Item>
					<List.Item>Instagram</List.Item>
					<List.Item>Twitter</List.Item>
					<List.Item>LinkedIn</List.Item>
				</List>
				<Space h="xs" />
				<Text>
					{`If You decide to register through or otherwise grant us access to a
	Third-Party Social Media Service, We may collect Personal data that is
	already associated with Your Third-Party Social Media Service's
	account, such as Your name, Your email address, Your activities or
	Your contact list associated with that account.`}
				</Text>
				<Space h="xs" />
				<Text>
					{`You may also have the option of sharing additional information with
the Company through Your Third-Party Social Media Service's account.
If You choose to provide such information and Personal Data, during
registration or otherwise, You are giving the Company permission to
use, share, and store it in a manner consistent with this Privacy
Policy.`}
				</Text>
				<Space h="md" />
				<Text className="text-md font-semibold">
					Tracking Technologies and Cookies{' '}
				</Text>
				<Text>
					We use Cookies and similar tracking technologies to track the activity
					on Our Service and store certain information. Tracking technologies
					used are beacons, tags, and scripts to collect and track information
					and to improve and analyze Our Service. The technologies We use may
					include:
				</Text>
				<Space h="xs" />
				<List icon={<Text>-</Text>}>
					<List.Item>
						<strong>Cookies or Browser Cookies.</strong> A cookie is a small
						file placed on Your Device. You can instruct Your browser to refuse
						all Cookies or to indicate when a Cookie is being sent. However, if
						You do not accept Cookies, You may not be able to use some parts of
						our Service. Unless you have adjusted Your browser setting so that
						it will refuse Cookies, our Service may use Cookies.
					</List.Item>
					<List.Item>
						<strong>Web Beacons.</strong> Certain sections of our Service and
						our emails may contain small electronic files known as web beacons
						(also referred to as clear gifs, pixel tags, and single-pixel gifs)
						that permit the Company, for example, to count users who have
						visited those pages or opened an email and for other related website
						statistics (for example, recording the popularity of a certain
						section and verifying system and server integrity).
					</List.Item>
				</List>
				<Space h="xs" />
				<Text>
					Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies.
					Persistent Cookies remain on Your personal computer or mobile device
					when You go offline, while Session Cookies are deleted as soon as You
					close Your web browser. You can learn more about cookies on{' '}
					<Anchor
						href="https://www.termsfeed.com/blog/cookies/#What_Are_Cookies"
						target="_blank"
					>
						TermsFeed website
					</Anchor>{' '}
					article.
				</Text>
				<Space h="xs" />
				<Text>
					We use both Session and Persistent Cookies for the purposes set out
					below:
				</Text>
				<List icon={<Text>-</Text>}>
					<List.Item>
						<strong>Cookies or Browser Cookies.</strong> A cookie is a small
						file placed on Your Device. You can instruct Your browser to refuse
						all Cookies or to indicate when a Cookie is being sent. However, if
						You do not accept Cookies, You may not be able to use some parts of
						our Service. Unless you have adjusted Your browser setting so that
						it will refuse Cookies, our Service may use Cookies.
					</List.Item>
					<List.Item>
						<strong>Web Beacons.</strong> Certain sections of our Service and
						our emails may contain small electronic files known as web beacons
						(also referred to as clear gifs, pixel tags, and single-pixel gifs)
						that permit the Company, for example, to count users who have
						visited those pages or opened an email and for other related website
						statistics (for example, recording the popularity of a certain
						section and verifying system and server integrity).
					</List.Item>
				</List>
				<Space h="xs" />
				<Text className="text-md font-semibold">
					Necessary / Essential Cookies
				</Text>
				<List icon={<Text>-</Text>}>
					<List.Item>Type: Session Cookies</List.Item>
					<List.Item>Administered by: Us</List.Item>
					<List.Item>
						Purpose: These Cookies are essential to provide You with services
						available through the Website and to enable You to use some of its
						features. They help to authenticate users and prevent fraudulent use
						of user accounts. Without these Cookies, the services that You have
						asked for cannot be provided, and We only use these Cookies to
						provide You with those services.
					</List.Item>
				</List>
				<Space h="xs" />
				<Text className="text-md font-semibold">
					Cookies Policy / Notice Acceptance Cookies
				</Text>
				<List icon={<Text>-</Text>}>
					<List.Item>Type: Persistent Cookies</List.Item>
					<List.Item>Administered by: Us</List.Item>
					<List.Item>
						Purpose: These Cookies identify if users have accepted the use of
						cookies on the Website.
					</List.Item>
				</List>
				<Space h="xs" />
				<Text className="text-md font-semibold">Functionality Cookies</Text>
				<List icon={<Text>-</Text>}>
					<List.Item>Type: Persistent Cookies</List.Item>
					<List.Item>Administered by: Us</List.Item>
					<List.Item>
						Purpose: These Cookies allow us to remember choices You make when
						You use the Website, such as remembering your login details or
						language preference. The purpose of these Cookies is to provide You
						with a more personal experience and to avoid You having to re-enter
						your preferences every time You use the Website.
					</List.Item>
				</List>
				<Space h="xs" />
				<Text>
					For more information about the cookies we use and your choices
					regarding cookies, please visit our Cookies Policy or the Cookies
					section of our Privacy Policy.
				</Text>
				<Space h="xl" />
				<Text className="text-xl font-semibold">Use of Your Personal Data</Text>
				<Space h="xs" />
				<Text>
					The Company may use Personal Data for the following purposes:
				</Text>
				<Space h="xs" />
				<List icon={<Text>-</Text>}>
					<List.Item>
						<strong>To provide and maintain our Service</strong>, including to
						monitor the usage of our Service.
					</List.Item>
					<List.Item>
						<strong>To manage Your Account:</strong> to manage Your registration
						as a user of the Service. The Personal Data You provide can give You
						access to different functionalities of the Service that are
						available to You as a registered user.
					</List.Item>
					<List.Item>
						<strong>For the performance of a contract:</strong> the development,
						compliance and undertaking of the purchase contract for the
						products, items or services You have purchased or of any other
						contract with Us through the Service.
					</List.Item>
					<List.Item>
						<strong>To contact You:</strong>
						{`To contact You by email,
							telephone calls, SMS, or other equivalent forms of electronic
							communication, such as a mobile application's push notifications
							regarding updates or informative communications related to the
							functionalities, products or contracted services, including the
							security updates, when necessary or reasonable for their
							implementation.`}
					</List.Item>
					<List.Item>
						<strong>To provide You</strong> with news, special offers and
						general information about other goods, services and events which we
						offer that are similar to those that you have already purchased or
						enquired about unless You have opted not to receive such
						information.
					</List.Item>
					<List.Item>
						<strong>To manage Your requests:</strong> To attend and manage Your
						requests to Us.
					</List.Item>
					<List.Item>
						<strong>For business transfers:</strong> We may use Your information
						to evaluate or conduct a merger, divestiture, restructuring,
						reorganization, dissolution, or other sale or transfer of some or
						all of Our assets, whether as a going concern or as part of
						bankruptcy, liquidation, or similar proceeding, in which Personal
						Data held by Us about our Service users is among the assets
						transferred.
					</List.Item>
					<List.Item>
						<strong>For other purposes</strong>: We may use Your information for
						other purposes, such as data analysis, identifying usage trends,
						determining the effectiveness of our promotional campaigns and to
						evaluate and improve our Service, products, services, marketing and
						your experience.
					</List.Item>
				</List>
				<Space h="xs" />
				<Text>
					We may share Your personal information in the following situations:
				</Text>
				<List icon={<Text>-</Text>}>
					<List.Item>
						<strong>With Service Providers:</strong> We may share Your personal
						information with Service Providers to monitor and analyze the use of
						our Service, to contact You.
					</List.Item>
					<List.Item>
						<strong>For business transfers:</strong> We may share or transfer
						Your personal information in connection with, or during negotiations
						of, any merger, sale of Company assets, financing, or acquisition of
						all or a portion of Our business to another company.
					</List.Item>
					<List.Item>
						<strong>With Affiliates:</strong> We may share Your information with
						Our affiliates, in which case we will require those affiliates to
						honor this Privacy Policy. Affiliates include Our parent company and
						any other subsidiaries, joint venture partners or other companies
						that We control or that are under common control with Us.
					</List.Item>
					<List.Item>
						<strong>With business partners:</strong> We may share Your
						information with Our business partners to offer You certain
						products, services or promotions.
					</List.Item>
					<List.Item>
						<strong>With other users:</strong> when You share personal
						information or otherwise interact in the public areas with other
						users, such information may be viewed by all users and may be
						publicly distributed outside. If You interact with other users or
						register through a Third-Party Social Media Service, Your contacts
						on the Third-Party Social Media Service may see Your name, profile,
						pictures and description of Your activity. Similarly, other users
						will be able to view descriptions of Your activity, communicate with
						You and view Your profile.
					</List.Item>
					<List.Item>
						<strong>With Your consent</strong>: We may disclose Your personal
						information for any other purpose with Your consent.
					</List.Item>
				</List>
				<Space h="xl" />
				<Text className="text-xl font-semibold">
					Retention of Your Personal Data
				</Text>
				<Space h="xs" />

				<Text>
					The Company will retain Your Personal Data only for as long as is
					necessary for the purposes set out in this Privacy Policy. We will
					retain and use Your Personal Data to the extent necessary to comply
					with our legal obligations (for example, if we are required to retain
					your data to comply with applicable laws), resolve disputes, and
					enforce our legal agreements and policies.
				</Text>
				<Space h="xs" />
				<Text>
					The Company will also retain Usage Data for internal analysis
					purposes. Usage Data is generally retained for a shorter period of
					time, except when this data is used to strengthen the security or to
					improve the functionality of Our Service, or We are legally obligated
					to retain this data for longer time periods.
				</Text>
				<Space h="xl" />
				<Text className="text-xl font-semibold">
					Transfer of Your Personal Data{' '}
				</Text>
				<Space h="xs" />
				<Text>
					{`Your information, including Personal Data, is processed at the
	Company's operating offices and in any other places where the parties
	involved in the processing are located. It means that this information
	may be transferred to — and maintained on — computers located outside
	of Your state, province, country or other governmental jurisdiction
	where the data protection laws may differ than those from Your
	jurisdiction.`}
				</Text>
				<Space h="xs" />
				<Text>
					Your consent to this Privacy Policy followed by Your submission of
					such information represents Your agreement to that transfer.
				</Text>
				<Space h="xs" />
				<Text>
					The Company will take all steps reasonably necessary to ensure that
					Your data is treated securely and in accordance with this Privacy
					Policy and no transfer of Your Personal Data will take place to an
					organization or a country unless there are adequate controls in place
					including the security of Your data and other personal information.
				</Text>
				<Space h="xl" />
				<Text className="text-xl font-semibold">Delete Your Personal Data</Text>
				<Space h="xs" />
				<Text>
					You have the right to delete or request that We assist in deleting the
					Personal Data that We have collected about You.
				</Text>
				<Space h="xs" />

				<Text>
					Our Service may give You the ability to delete certain information
					about You from within the Service.
				</Text>
				<Space h="xs" />

				<Text>
					You may update, amend, or delete Your information at any time by
					signing in to Your Account, if you have one, and visiting the account
					settings section that allows you to manage Your personal information.
					You may also contact Us to request access to, correct, or delete any
					personal information that You have provided to Us.
				</Text>
				<Space h="xs" />
				<Text>
					Please note, however, that We may need to retain certain information
					when we have a legal obligation or lawful basis to do so.
				</Text>
				<Space h="xl" />
				<Text className="text-xl font-semibold">
					Disclosure of Your Personal Data
				</Text>
				<Space h="xs" />
				<Text className="text-md font-semibold">Business Transactions</Text>
				<Space h="xs" />
				<Text>
					If the Company is involved in a merger, acquisition or asset sale,
					Your Personal Data may be transferred. We will provide notice before
					Your Personal Data is transferred and becomes subject to a different
					Privacy Policy.
				</Text>
				<Space h="md" />
				<Text className="text-md font-semibold">Law enforcement</Text>
				<Space h="xs" />
				<Text>
					Under certain circumstances, the Company may be required to disclose
					Your Personal Data if required to do so by law or in response to valid
					requests by public authorities (e.g. a court or a government agency).
				</Text>
				<Space h="md" />
				<Text className="text-md font-semibold">Other legal requirements</Text>
				<Space h="xs" />
				<Text>
					The Company may disclose Your Personal Data in the good faith belief
					that such action is necessary to:
				</Text>
				<Space h="xs" />
				<List icon={<Text>-</Text>}>
					<List.Item>Comply with a legal obligation</List.Item>
					<List.Item>
						Protect and defend the rights or property of the Company
					</List.Item>
					<List.Item>
						Prevent or investigate possible wrongdoing in connection with the
						Service
					</List.Item>
					<List.Item>
						Protect the personal safety of Users of the Service or the public
					</List.Item>
					<List.Item>Protect against legal liability</List.Item>
				</List>
				<Space h="md" />
				<Text className="text-md font-semibold">
					Security of Your Personal Data
				</Text>
				<Space h="xs" />
				<Text>
					The security of Your Personal Data is important to Us, but remember
					that no method of transmission over the Internet, or method of
					electronic storage is 100% secure. While We strive to use commercially
					acceptable means to protect Your Personal Data, We cannot guarantee
					its absolute security.
				</Text>
				<Space h="xl" />
				<Text className="text-xl font-semibold">{`Children's Privacy`}</Text>
				<Space h="xs" />
				<Text>
					Our Service does not address anyone under the age of 13. We do not
					knowingly collect personally identifiable information from anyone
					under the age of 13. If You are a parent or guardian and You are aware
					that Your child has provided Us with Personal Data, please contact Us.
					If We become aware that We have collected Personal Data from anyone
					under the age of 13 without verification of parental consent, We take
					steps to remove that information from Our servers.
				</Text>
				<Space h="xs" />
				<Text>
					{`If We need to rely on consent as a legal basis for processing Your
	information and Your country requires consent from a parent, We may
	require Your parent's consent before We collect and use that
	information.`}
				</Text>
				<Space h="xl" />
				<Text className="text-xl font-semibold">Links to Other Websites</Text>
				<Space h="xs" />

				<Text>
					{`Our Service may contain links to other websites that are not operated
	by Us. If You click on a third party link, You will be directed to
	that third party's site. We strongly advise You to review the Privacy
	Policy of every site You visit.`}
				</Text>
				<Space h="xs" />

				<Text>
					We have no control over and assume no responsibility for the content,
					privacy policies or practices of any third party sites or services.
				</Text>

				<Space h="xl" />
				<Text className="text-xl font-semibold">
					Changes to this Privacy Policy
				</Text>
				<Space h="xs" />

				<Text>
					We may update Our Privacy Policy from time to time. We will notify You
					of any changes by posting the new Privacy Policy on this page.
				</Text>
				<Space h="xs" />

				<Text>
					We will let You know via email and/or a prominent notice on Our
					Service, prior to the change becoming effective and update the
					&quot;Last updated&quot; date at the top of this Privacy Policy.
				</Text>
				<Space h="xs" />

				<Text>
					You are advised to review this Privacy Policy periodically for any
					changes. Changes to this Privacy Policy are effective when they are
					posted on this page.
				</Text>

				<Space h="xl" />
				<Text className="text-xl font-semibold">Contact Us</Text>
				<Space h="xs" />
				<Text>
					If you have any questions about this Privacy Policy, You can contact
					us:
				</Text>
				<Space h="xs" />
				<Text>
					<strong>Website</strong> refers to tcgunite, accessible from{' '}
					<Anchor onClick={() => navigate(HomeRoutes.Home)}>
						https://www.tcgunite.co/contact-us
					</Anchor>
				</Text>
			</ResponsiveContainer>
			<Space className="h-28" />
		</ViewContainer>
	);
};

export default PrivacyPolicy;
